import config from "../lib/config"

export function useProd() {
  const isProd = config.stage === "PROD";

  const shouldBeAbleToEdit = false;
  const shouldShowClearButton = !isProd;
  
  return {
    shouldBeAbleToEdit,
    shouldShowClearButton,
  }
}