import { IAnswer, IUser } from "../interfaces/user.interface";
import config from "../lib/config";

export async function getUser(userId: string): Promise<IUser> {
  const res = await fetch(config.apiUrl!, {
    method: "GET",
    headers: {
      Uid: userId,
    },
  });

  const user = await res.json();
  return user;
}

export async function agreeToTos(userId: string): Promise<IUser> {
  const res = await fetch(config.apiUrl!, {
    method: "PATCH",
    headers: {
      Uid: userId,
    },
  });

  const user = await res.json();
  return user;
}

export async function updateAnswers(userId: string, answers: IAnswer[]): Promise<IUser> {
  const res = await fetch(config.apiUrl!, {
    method: "POST",
    headers: {
      Uid: userId,
    },
    body: JSON.stringify({
      answers,
    }),
  });

  const updatedUser = await res.json();
  return updatedUser;
}

export async function clearUser(userId: string): Promise<IUser> {
  const res = await fetch(config.apiUrl!, {
    method: "DELETE",
    headers: {
      Uid: userId,
    },
  });

  const user = await res.json();
  return user;
}

export async function allCoordinates(): Promise<IAnswer[]> {
  const res = await fetch(config.apiUrl!, {
    method: "CONNECT",
  });

  const answers = await res.json();
  return answers;
}