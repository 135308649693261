import { useState } from "react";
import style from "./TosModal.module.css";
import Modal from "react-modal";
import Button from "../Button";
import { useModal } from "../../hooks/useModal";
import { useWindowSize } from "../../hooks/useWindowSize";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAgreed: () => void;
  loading: boolean;
}

export function TosModal({ isOpen, onClose, onAgreed, loading }: Props) {
  const { height } = useWindowSize();
  const modalHeight = height * 0.8;

  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const { contentLeft, contentTop } = useModal({ contentRef, isOpen, modalHeight });

  const [tosChecked, setTosChecked] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      contentRef={setContentRef}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          width: "95%",
          maxWidth: "860px",
          height: "100%",
          maxHeight: height * 0.8,
          left: contentLeft + "px",
          top: contentTop + "px",
          overflowY: "hidden",

          opacity: loading ? 0.5 : 1,
          pointerEvents: loading ? "none" : undefined,
        },
      }}
    >
      <div className={style.content}>
        <h1 className={style.header}>თამაშის წესები</h1>

        <div className={style.scroll}>
          <div className={style.tosText}>
            <p>
              "Hubs Game" არის უნარებზე დაფუძნებული შეჯიბრი, სადაც მონაწილეებმა უნდა გამოიცნონ ბურთის მდებარეობა მოცემულ სურათზე. ეს შეჯიბრი არ შეიცავს შემთხვევითობის ელემენტებს და ეფუძნება მონაწილეთა უნარებს.
            </p>

            <p>
              ვინაიდან თქვენ, 05 ივნისამდე, ვებ-გვერდზე www.hubs.ge შეიძინეთ საქართველოს ეროვნული საფეხბურთო ნაკრების ოფიციალური ან ფან-მაისური, უფლება გაქვთ მონაწილეობა მიიღოთ ჩვენს გათამაშებაში, სადაც საშუალება გექნებათ მოიგოთ ევროპის ჩემპიონატის ჯგუფურ ეტაპზე საქართველოს ეროვნული ნაკრების მიერ გასამართ მატჩებზე დასასწრები ბილეთები.
            </p>
              
            <p>
              თამაშდება 2 ბილეთი საქართველო - თურქეთის, საქართველო - ჩეხეთის და საქართველო - პორტუგალიის მატჩებზე. 
            </p>
              
            <p>
              ჩვენი გათამაშება ტარდება „Hubs Game“ სახელით, რომელიც ეფუძნება ძველ თამაშს სახელწოდებით "Spot The Ball". 
            </p>

            <p>
              თამაშის მონაწილეებს საშუალება გექნებათ იხილოთ ფოტო საფეხბურთო მატჩიდან, სადაც წაშლილია ბურთი. თქვენ უნდა გამოიყენოთ თქვენი უნარი, ლოგიკა და გამოცდილება, რათა გადაწყვიტოთ, თუ სად არის ბურთის ცენტრი. თქვენი არჩეული წერტილი ჩაიწერება (x,y) კოორდინატებად, შეინახება ჩვენს მონაცემთა ბაზაში და თქვენს ანგარიშში. 
            </p>

            <p>
              იმ მომხმარებლებს, რომლებსაც შეძენილი გაქვთ ეროვნული ნაკრების ოფიციალური მაისური, „Hubs Game“-ში თითოეული მაისურისთვის მიიღებთ 5 ბილეთს, ხოლო, იმ მომხმარებლებს, რომლებსაც შეძენილი გაქვთ ფან-მაისური - თითოეული მასურისთვის მიიღებთ 1 ბილეთს.
            </p>

            <p>
              კონკურსი დაიხურება <b>8 ივნისს, 23:59-ზე.</b> 9 ივნისს გამოვლინდებიან გამარჯვებულები. გამარჯვებულია ის სამი ბილეთი, რომელიც ემთხვევა ან ყველაზე ახლოსაა ბურთის რეალურ ცენტრთან. 
            </p>

            <p>
              გამარჯვებული, რომლის კოორდინატები ემთხვევა ან ყველაზე ახლოსაა ბურთის რეალურ ცენტრთან, აირჩევს ეროვნული ნაკრების 3 მატჩიდან ერთ-ერთს, რომელზეც უნდა დასწრება და მიიღებს 2 ბილეთს. მეორე ადგილოსანი - აირჩევს დარჩენილი ორი მატჩიდან ერთ-ერთს და მიიღებს 2 ბილეთს, ხოლო მესამე ადგილოსანი მიიღებს დარჩენილი 1 მატჩის 2 ბილეთს. 
            </p>

            <p>
              თუ კონკურსში მონაწილე ვერ გაიმარჯვებს, ის არ მიიღებს პრიზს.
            </p>

            <p>
              „Hubs Game“ რეგულირდება საქართველოს კანონმდებლობით და სრულ შესაბამისობაშია მასთან.
            </p>

            <p>
              „Hubs Game“-ში ჩართვით, მონაწილეები თანხმდებიან დაიცვან წინამდებარე პირობები და www.hubs.ge მიერ დამატებით დადგენილი მოთხოვნები.
            </p>
          </div>

          <div style={{ flex: 1 }} />

          <div className={style.inputContainer}>
            <input id="tosCheckbox" type="checkbox" defaultChecked={tosChecked} onChange={() => setTosChecked(!tosChecked)} />
            <label htmlFor="tosCheckbox">ვეთანხმები მოცემულ პირობებს</label>
          </div>

          <Button onClick={onAgreed} text="დადასტურება" theme="primary" disabled={!tosChecked} />
        </div>
      </div>
    </Modal>
  );
}
