import { useState } from "react";
import style from "./SubmitModal.module.css";
import Modal from 'react-modal';
import Button from "../Button";
import { useModal } from "../../hooks/useModal";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitting: boolean;

  modalHeight: number;
  attemptsLeft: number;
}

export default function SubmitModal({
  isOpen,
  onClose,
  onSubmit,
  submitting,
  modalHeight,
  attemptsLeft = 0,
}: Props) {
  const [contentRef, setContentRef] = useState<HTMLDivElement | null>(null);
  const { contentLeft, contentTop } = useModal({ contentRef, modalHeight, isOpen });

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      contentRef={setContentRef}
      style={{
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
        content: {
          width: "95%",
          maxWidth: "560px",
          maxHeight: "260px",
          left: contentLeft + 'px',
          top: contentTop + 'px',
          
          opacity: submitting ? 0.5 : 1,
          pointerEvents: submitting ? "none" : undefined,
        }
      }}
    >
      <div className={style.content}>
        {!!attemptsLeft && (
          <p>გაითვალისწინეთ რომ დარჩენილი გაქვთ კიდევ {attemptsLeft} ცდა!</p>
        )}
        
        <p>{!attemptsLeft && "გაითვალისწინეთ რომ"} პასუხის მეორედ არჩევას ვეღარ შეძლებთ!</p>
        
        <div className={style.separator} />
        
        <div className={style.buttonsContainer}>
          <Button
            onClick={onClose}
            text="დახურვა"
            theme="primary"
            className={style.cancelButton}
          />

          <Button
            onClick={onSubmit}
            text="დადასტურება"
            theme="primary"
            className={style.submitButton}
          />
        </div>
      </div>
    </Modal>
  )
}