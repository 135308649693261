import { useMemo } from "react";
import { useWindowSize } from "./useWindowSize";

interface Props {
  contentRef: HTMLDivElement | null;
  isOpen: boolean;
  modalHeight: number;
}

export function useModal({ contentRef, isOpen, modalHeight }: Props) {
  const { width, height } = useWindowSize();

  const { contentLeft, contentTop } = useMemo(() => ({
    contentTop: height * 0.05,
    contentLeft: (width / 2) - (contentRef?.clientWidth! / 2),
  }), [contentRef, width, height, isOpen, modalHeight]);
  
  return {
    contentLeft,
    contentTop
  }
}