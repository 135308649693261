import { useEffect, useMemo, useState } from "react";
import Image from "./components/Image";
import AttemptCard from "./components/AttemptCard";
import style from "./App.module.css";
import { useWindowSize } from "./hooks/useWindowSize";
import { IAnswer, IUser } from "./interfaces/user.interface";
import { calculateRelativeAnswer } from "./lib/tools";
import { agreeToTos, getUser, updateAnswers } from "./api/user.api";
import SubmitModal from "./components/SubmitModal";
import BackgroundImage from "./components/BackgroundImage";
import { Header } from "./components/Header";
import { TosModal } from "./components/TosModal";
import { useProd } from "./hooks/useProd";
import { CENTER, WINNERS } from "./contsants";

function App() {
  const [attempts, setAttempts] = useState<IAnswer[]>([]);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const [hoveringAttemptInd, setHoveringAttemptInd] = useState<number | null>(null);

  const [user, setUser] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(false);

  const [tosModalOpen, setTosModalOpen] = useState(false);
  const [tosLoading, setTosLoading] = useState(false);

  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  
  const [imageLoaded, setImageLoaded] = useState(false);

  // This just re-renders the page after window resize
  const { width, height } = useWindowSize();
  
  const [winners, setWinners] = useState(WINNERS);

  const { shouldBeAbleToEdit } = useProd();
  const editDisabled = useMemo(() => {
    return !!(user?.answers?.length && !shouldBeAbleToEdit);
  }, [user, shouldBeAbleToEdit]);

  const [rerenderCounter2, setRerenderCounter2] = useState(0);
  useEffect(() => {
    if(rerenderCounter2 < 12) setRerenderCounter2(rerenderCounter2 + 1);
  }, [rerenderCounter2]);

  const imageHeight = useMemo(() => {
    return imageRef?.clientHeight;
  }, [imageRef, width, height, rerenderCounter2, imageLoaded]);

  useEffect(() => {
    setLoading(true);
    const url = window.location.href;
    const parts = url.split("?");
    const queryString = parts[1];
    const queryParams = new URLSearchParams(queryString);
    const userId = queryParams.get("uid");

    if (!userId) {
      // alert("გთხოვთ გადახვიდეთ მეილზე გამოგზავნილ ლინკზე!");
      // window.location.href = "https://hubs.ge";
      setLoading(false);
      return;
    }

    getUser(userId!)
      .then((res) => {
        if ("message" in res) {
          throw new Error(res.message as any);
        }

        setUser(res);
        setAttempts(res.answers);

        // if (!res.tosAgreed) {
        //   setTosModalOpen(true);
        // }

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        // alert(err?.message || "გთხოვთ თავიდან სცადოთ ლინკზე გადასვლა.");
        // window.location.href = "https://hubs.ge";
      });
  }, []);

  const handleAttempt = (attempt: IAnswer) => {
    if (editDisabled) {
      return alert("შენ უკვე არჩეული გაქვს პასუხები.");
    }

    if (attempts.length >= user?.maxAnswers!) {
      return alert("შენ უკვე აირჩიე მაქსიმალური რაოდენობის პასუხები.");
    }

    const { relX, relY } = calculateRelativeAnswer(attempt.x, attempt.y, imageRef?.clientWidth!, imageRef?.clientHeight!);

    if (attempts.find((a) => a.x === relX && a.y === relY)) {
      return alert("ამ წერტილში უკვე არჩეული გაქვს პასუხი.");
    }

    setAttempts((prevAttempts) => [...prevAttempts, { x: relX, y: relY }]);
  };

  const deleteAttempt = (index: number) => {
    setAttempts((prevAttempts) => {
      const updatedAttempts = [...prevAttempts];
      updatedAttempts.splice(index, 1);
      return updatedAttempts;
    });
  };

  function handleTosAgreement() {
    setTosLoading(true);

    agreeToTos(user?._id!)
      .then((res) => {
        if ("message" in res) {
          throw new Error(res.message as any);
        }

        setUser(res);
        setAttempts(res.answers);
        setTosModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        alert(err?.message || "გთხოვთ თავიდან სცადოთ.");
      })
      .finally(() => setTosLoading(true));
  }

  function handleSubmit() {
    setSubmitting(true);
    updateAnswers(user?._id!, attempts)
      .then((res) => {
        if ("message" in res) {
          throw new Error(res.message as any);
        }

        setUser(res);
        setAttempts(res.answers);
        setSubmitModalOpen(false);
      })
      .catch((err) => {
        console.error(err);
        alert(err?.message || "გთხოვთ თავიდან სცადოთ.")
      })
      .finally(() => setSubmitting(false));
  }

  if (loading) {
    return <h1 style={{ color: "white" }}>იტვირთება...</h1>;
  }

  return (
    <div className={`${style.container} ${submitting && style.containerSubmitting}`}>
      <Header />
      {/* 
      <div className={style.titles}>
        <h1>Spot the Ball</h1>

        {!editDisabled && <h2>დარჩენილი მცდელობები: {user ? user?.maxAnswers! - attempts?.length : "?"}</h2>}
      </div> */}

      <div className={style.content}>
        <Image
          imageLoaded={imageLoaded}
          setImageLoaded={setImageLoaded}
          hoveringAttemptInd={hoveringAttemptInd}
          attempts={attempts}
          onAttempt={handleAttempt}
          imageRef={imageRef}
          setImageRef={setImageRef}
          editDisabled={editDisabled}
        />

        <div className={style.right} style={{ height: imageHeight}}>
          <h2>
            გამარჯვებულები
            {user && "და შენი პასუხები"}
            {/* {user ? attempts?.length : 0}/{user?.maxAnswers} */}
          </h2>

          {imageLoaded && (
              <div className={style.attemptsWrapper}>
                {/* CENTER */}
                <AttemptCard
                  key={`cords-${CENTER.x}-${CENTER.y}`}
                  imageRef={imageRef}
                  coordinates={CENTER}
                  total={attempts.length}
                  editDisabled={editDisabled}
                  // color={"#00e500"}
                  center={true}
                />

                {/* WINNERS */}
                {winners.map((coordinates, index) => (
                  <AttemptCard
                    key={`cords-${coordinates.x}-${coordinates.y}`}
                    imageRef={imageRef}
                    coordinates={coordinates}
                    total={attempts.length}
                    editDisabled={editDisabled}
                    color={index === 0 ? "#FFD700" : index === 1 ? "#C0C0C0" : "#CD7F32"}
                  />
                ))}
                {attempts.map((coordinates, index) => (
                  <AttemptCard
                    key={`cords-${coordinates.x}-${coordinates.y}`}
                    imageRef={imageRef}
                    coordinates={coordinates}
                    total={attempts.length}
                    editDisabled={editDisabled}
                  />
                ))}
              </div>
          )}

          {/* {!editDisabled && (
            <>
              <Button className={style.submitButton} onClick={() => setSubmitModalOpen(true)} text="არჩევა" theme="primary" disabled={!(attempts.length > 0)} />
            </>
          )} */}

          {/* <ClearAnswersButton userId={user?._id!} /> */}
        </div>
      </div>

      <TosModal isOpen={tosModalOpen} onClose={() => setTosModalOpen(false)} onAgreed={handleTosAgreement} loading={tosLoading} />

      <SubmitModal
        isOpen={submitModalOpen}
        onClose={() => setSubmitModalOpen(false)}
        onSubmit={handleSubmit}
        submitting={submitting}
        modalHeight={260}
        attemptsLeft={user?.maxAnswers! - attempts.length}
      />

      <BackgroundImage />
    </div>
  );
}

export default App;
