export function calculateRelativeAnswer(x: number, y: number, width: number, height: number) {
  // Calculate relative position
  const relX = x / width;
  const relY = y / height;

  // Return the relative position
  return { relX, relY };
}

export function calculateRealAnswer(relativeX: number, relativeY: number, width: number, height: number): { realX: number, realY: number } {
    // Calculate real position
    const realX = relativeX * width;
    const realY = relativeY * height;

    // Return the real position
    return { realX, realY };
}