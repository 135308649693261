import style from "./Header.module.css";

export const Header = () => {
  return (
    <header className={style.header}>
      <div className={style.left}>
        <a href="tel:+995 574238811">
          <img className={style.icon} src="https://hubs.ge/img/icons/phone.svg" alt="Phone" />
          <p>+995 574 23 88 11</p>
        </a>

        <a href="mailto:info@hubs.ge">
          <img className={style.icon} src="https://hubs.ge/img/icons/email.svg" alt="Email" />
          <p>info@hubs.ge</p>
        </a>
      </div>

      <div className={style.right}>
        <a href="https://www.facebook.com/mshtbilisi" target="_blank" rel="noreferrer">
          <img className={style.icon} src="/fb.svg" alt="Facebook" />
        </a>

        <a href="https://www.instagram.com/macronsportshubtbilisi" target="_blank" rel="noreferrer">
          <img className={style.icon} src="/ig.svg" alt="Instagram" />
        </a>
      </div>
    </header>
  );
};
