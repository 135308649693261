import React, { MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import config from "../../lib/config";
import style from "./Image.module.css";
import { useWindowSize } from "../../hooks/useWindowSize";
import { IAnswer } from "../../interfaces/user.interface";
import { calculateRealAnswer } from "../../lib/tools";
import { WINNERS } from "../../contsants";

interface MousePosition {
  x: number;
  y: number;
}

interface Props {
  imageLoaded: boolean;
  setImageLoaded: any;
  attempts: IAnswer[];
  imageRef: HTMLImageElement | null;
  hoveringAttemptInd: number | null;
  onAttempt: (attempt: IAnswer) => void;
  setImageRef: React.Dispatch<React.SetStateAction<HTMLImageElement | null>>;
  editDisabled: boolean;
}

export default function Image({ imageLoaded, setImageLoaded, attempts, imageRef, hoveringAttemptInd, onAttempt, setImageRef, editDisabled }: Props) {
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });
  const [isInside, setIsInside] = useState<boolean>(false);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setIsInside(true);
  };

  const handleMouseMove: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (isInside) {
      setMousePosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = () => {
    setIsInside(false);
  };

  const cursor = useMemo(() => {
    if (!imageContainerRef.current) return;
    if (!isInside) return;
    if (editDisabled) return;

    const rect = imageContainerRef.current.getBoundingClientRect();

    return (
      <img
        src="/marker.svg"
        className={`${style.attempt} ${style.cursor}`}
        style={{
          left: mousePosition.x - rect.left,
          top: mousePosition.y - rect.top,
          zIndex: 9999,
        }}
        alt=""
      />
    );
  }, [mousePosition]);

  // This just re-renders the page after window resize
  const {} = useWindowSize();

  const [rerenderCounter, setRerenderCounter] = useState(0);
  useEffect(() => {
    setRerenderCounter(0);
  }, [attempts, imageRef]);

  useEffect(() => {}, []);

  const handleClick = (event: MouseEvent<HTMLImageElement>) => {
    if (editDisabled) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    onAttempt({ x, y });
  };
  
  const [rerenderCounter3, setRerenderCounter3] = useState(0);

  return (
    <div
      className={style.imageContainer}
      ref={imageContainerRef}
      // style={{ cursor: !editDisabled && isInside ? "none" : "auto" }}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {/* The Main Image */}
      <img
        ref={(ref)=> setImageRef(ref)}
        src={config.imageUrl}
        className={style.image}
        alt=""
        draggable={false}
        onLoad={() => setImageLoaded(true)}
      />

      
      {imageLoaded && (
        <>

      {/* Cursor */}
      {/* {isInside && cursor} */}
      
      {/* WINNERS */}
      {WINNERS.map(({ x, y }, ind) => {
        const { realX, realY } = calculateRealAnswer(x, y, imageRef?.clientWidth!, imageRef?.clientHeight!);

        if (rerenderCounter3 < 6) setRerenderCounter3(rerenderCounter3 + 1);

        return (
          <img
            key={`${x}-${y}`}
            src={`/marker-winner-${ind + 1}.svg`}
            style={{
              left: realX || 0,
              top: realY || 0,
              zIndex: 9999 - ind,
            }}
            className={style.attempt}
            alt=""
          />
        )
      })}

      {/* Attempts */}
      {attempts.map(({ x, y }, ind) => {
        const { realX, realY } = calculateRealAnswer(x, y, imageRef?.clientWidth!, imageRef?.clientHeight!);

        // Rerender this object 3 times.
        // If there are many number of attempts on the screen
        // it needs at least 1 rerender for them to be placed correctly.
        // If not, they will be slightly off.
        if (rerenderCounter < 3) setRerenderCounter(rerenderCounter + 1);

        return (
          <img
            key={`${x}-${y}`}
            src="/marker.svg"
            style={{
              left: realX || 0,
              top: realY || 0,
            }}
            className={style.attempt}
            alt=""
          />
        )
      })}
        </>
      )}
    </div>
  );
}
