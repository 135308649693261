import style from "./Button.module.css";

interface Props {
  text: string;
  theme: "primary" | "secondary";
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

export default function Button({ text, onClick, theme, disabled, className }: Props) {
  return (
    <button
      className={`
        ${style.button}
        ${theme === "primary" ? style.primary : style.secondary}
        ${disabled && style.disabled}
        ${className}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
